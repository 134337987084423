<template lang="pug">
	footer.page-footer
		.footer-content(:class='{"footer-content-without-login" : !me || fullWidth}')
			.container-fluid
				.d-flex.align-md-items-center.justify-content-between.flex-column.flex-md-row
					ul.d-flex.list-unstyled
						li.text-muted.text-nowrap.mr-4 Paywall.pw {{ date }}

					ul.d-flex.list-unstyled.float-right
						li(v-for='i in items')
							router-link.text-default(:to='{name: i.name}' target='_blank') {{ i.title }}
</template>

<script>
import moment from 'moment';
import { bus } from '@/main.js';

export default {
    props: {
        fullWidth: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({}),
    computed: {
        date() {
            return moment().format('YYYY');
        },
        items() {
            return [
                { name: 'terms', title: bus.$t('h1.serviceRules') },
                { name: 'privacy', title: bus.$t('h1.servicePrivacy') },
            ];
        },
    },
};
</script>

<style lang="scss" scoped>
.list-unstyled {
    margin: 0;
    padding: 0;

    li {
        font-size: 12px;
        line-height: 14px;
        margin-right: 24px;
        &:last-child {
            margin-right: 0;
        }
    }
}

// .company-name

@media (max-width: 768px) {
    .list-unstyled {
        flex-direction: column;

        &.float-right {
            float: left !important;
        }

        li {
            display: block;
            width: 100%;
            padding-left: 0;
            margin-bottom: 20px;
            margin-right: 0;

            &:before {
                display: none;
            }
        }
    }
}
</style>
